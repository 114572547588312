<style scoped lang="scss">
  
</style>

<template>
  <div class="loginlogs_list_box">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fr">
              <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字" clearable v-model="listQuery.keywords" size="medium"></el-input>
              <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <div>
      <el-table :data="tableData" border fit highlight-current-row>
        <el-table-column label="操作者" prop="userName" width="120"></el-table-column>
        <el-table-column label="操作" prop="customData" width="200">
          <template slot-scope="scope">
            <div class="contentHover" v-html="scope.row.customData"></div>
          </template>
        </el-table-column>
        <el-table-column label="参数">
          <template slot-scope="scope">
            <div>方法名：{{ scope.row.methodName }}</div>
            <div>参数：{{ scope.row.parameters }}</div>
          </template>
        </el-table-column>
        <el-table-column label="IP" prop="clientIpAddress" width="150"></el-table-column>
        <el-table-column label="操作时间" prop="executionTime" width="160"></el-table-column>
      </el-table>
    </div>

    <div class="pagination-container" v-if="isPagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
      :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
      layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import * as auditLogs from '@/api/system/user'
  export default {
    name: "operationLogs",
    data() {
      return {
        tableData: [],
        total: null,
        listQuery: {
          keywords: null,
          page: 1,
          size: window.$common.appPageSize,
          userType: 1
        },
        isPagination: false
      }
    },
    mounted() {
      this.getListData()
    },
    methods: {
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getListData()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getListData()
      },
      getListData() {
        window.$common.fullLoading()
        auditLogs.getAuditLogs(this.listQuery).then(response => {
          window.$common.closeFullLoading()
          this.total = response.totalCount
          this.isPagination = response.totalCount > 0 || false
          if (response && response.items && response.items.length > 0) {
            this.tableData = response.items
          } else {
            this.tableData = []
          }
        })
      },
      _search() {
        this.listQuery.page = 1
        this.getListData()
      }
    }
  }
</script>